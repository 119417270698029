import React, { useState } from "react";
import { scroller } from "react-scroll";
import LciForm from "../components/LciForm";
import page from "../components/page";
import Container from "../components/Container";
import { Title } from "../components/typography";
import { mY } from "../components/spacingShorthands";
import ClimateFacts from "../components/ClimateFacts";
import { callApi } from "../components/api";
import { trackNProgress } from "../components/nProgress";

export default page(() => {
  const [facts, setFacts] = useState();
  return (
    <Container>
      <Title css={{ textAlign: "center", ...mY(48) }}>
        Product footprint calculator
      </Title>
      <LciForm
        css={mY(48)}
        onSubmit={async (lci) => {
          const res = await trackNProgress(
            callApi({
              method: "POST",
              path: "products/computeFootprint",
              params: { lci },
            })
          );
          setFacts(res.facts);
          scroller.scrollTo("facts", {
            offset: -96,
            smooth: true,
            duration: 500,
          });
          return false;
        }}
      />
      <div name="facts">
        {facts && <ClimateFacts css={mY(48)} facts={facts} />}
      </div>
    </Container>
  );
});
